import * as THREE from 'three';

const textureLoader = new THREE.TextureLoader();

const lineReatCount = 1;

export const secondaryTexture = textureLoader.load('texture/green_line.png');
secondaryTexture.wrapS = THREE.RepeatWrapping;
secondaryTexture.wrapT = THREE.RepeatWrapping;
secondaryTexture.repeat.set(lineReatCount,lineReatCount);

export const trunkTexture = textureLoader.load('texture/red_line.png');
trunkTexture.wrapS = THREE.RepeatWrapping;
trunkTexture.wrapT = THREE.RepeatWrapping;
trunkTexture.repeat.set(lineReatCount,lineReatCount);

export const primaryTexture = textureLoader.load('texture/blue_line.png');
primaryTexture.wrapS = THREE.RepeatWrapping;
primaryTexture.wrapT = THREE.RepeatWrapping;
primaryTexture.repeat.set(lineReatCount,lineReatCount);

export const skyTexture = textureLoader.load('texture/night.jpg');

export const pointTexture = textureLoader.load('texture/TX-GY-021_p1.png');