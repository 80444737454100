import { render, staticRenderFns } from "./LoaderView.vue?vue&type=template&id=27bc7cf6&scoped=true"
import script from "./LoaderView.vue?vue&type=script&lang=js"
export * from "./LoaderView.vue?vue&type=script&lang=js"
import style0 from "./LoaderView.vue?vue&type=style&index=0&id=27bc7cf6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27bc7cf6",
  null
  
)

export default component.exports