<template>
    <div class="display-wrapper">
        <div class="display">
            <h3>{{ data['cameraname'] }}</h3>
            <!-- <h6>{{ data['address'] }}</h6> -->
            <video autoplay loop controls muted style="width: 300px;" id="camera"></video>
            <div class="close" @click="closeWindow()"></div>
        </div>
        
    </div>
</template>

<script>
import bus from '@/bus';

export default{
    name: 'VideoDisplay',
    comments: {},
    data(){
        return {
            opened: false
        };
    },
    props: ['data'],
    methods: {
        play(cameraid){
            this.stop();
            console.log('play');
            this.opened = true;
            let video = document.getElementById('camera');

            var num = parseInt(cameraid);
            var n = num % 3;
            video.src = `/movie/${n}.mp4`;
        },
        stop(){
            if(this.opened){
                console.log('stop');
                let video = document.getElementById('camera');
                video.pause();
                this.opened = false;
            }
        },
        closeWindow(){
            this.stop();
            bus.$emit('videoDisplayColse');
        },
        messageHander(event){
            console.log(event);
        }
    },
    mounted(){

    }   
}
</script>

<style lang="less" scoped>
   .display-wrapper{
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    color: #fff;
    opacity: 1;
    transition: none;
    z-index: 3;
   }

   .display-wrapper .display{
    padding: 2px 5px 2px 5px;
    border-radius: 0;
    display: inline-block;
    position: relative;
    margin-left: -50%;
    margin-top: -100%;
    top: -83px;
    opacity: 1;
    border: 1px solid #2294ff;
    background: linear-gradient(to bottom,#081a30,#003678);
    box-shadow: inset 0 0 10px rgba(13,150,255,0.8);
   }

   .close{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    background: url('../assets/image/close.png');
    background-size: 100% 100%;
    z-index: 10;
   }
</style>