<template>
  <div class="main">
    <LoaderView :number="loadingNumber" v-if="loadingNumber !== 100"></LoaderView>
    <BigHeader></BigHeader>
    <div id="screen" class="screen"></div>
    <BigControl class="control" :controls="controls"></BigControl>
    <!-- <TimeSelect class="timeSelect" id="timeSelect"></TimeSelect> -->
    <VideoDisplay :style="{
      visibility: videoDisplayStyle.show ? 'visible' : 'hidden',
      left: videoDisplayStyle.x + 'px',
      top: videoDisplayStyle.y + 'px'
    }" :data='videoDisplayStyle' ref="videoDisplay"></VideoDisplay>
    <Layer :layers="layerData" :active="currLayer" :styles="layerSelectorStyle" @change="changeLayer"
      v-if="isShowLayerSelector"></Layer>
  </div>

</template>

<script>
// @ is an alias to /src
import * as THREE from 'three';
import LoaderView from '@/components/LoaderView.vue';
import ThreeApp from '@/three/ThreeApp';
import { Loader } from '@/three/Loader';
import BigHeader from '@/components/Header.vue';
import BigControl from '@/components/BigControl.vue';
// import TimeSelect from '@/components/TimeSelect.vue';
import globalVariable from '@/GlobalVariable';
import { blackColor } from '@/three/material';
import VideoDisplay from '@/components/VideoDisplay.vue'
import Layer from '@/components/Layer.vue';

let app;
let cameraLabel = [];
let websocket;

export default {
  name: 'HomeView',
  components: {
    LoaderView,
    BigHeader,
    BigControl,
    // TimeSelect,
    VideoDisplay,
    Layer
  },
  data() {
    return {
      loadingNumber: 0,
      isShowLayerSelector: false,
      layerData: [],
      currLayer: '全楼',
      controls: [
        {
          name: '室外',
          goFunction: () => {
            const option = {
              position: new THREE.Vector3(179.02, 138.55, -179.12),
              target: new THREE.Vector3(26.98, 77.93, 89.35),
              finish: () => {
                globalVariable.canOperate = true;
                globalVariable.outsideCamera.forEach((label) => {
                  label.visible = true;
                });
              },
              duration: 1000
            };
            globalVariable.canOperate = false;
            app.flyTo(option);
          },
          backFunctioon: () => {
            globalVariable.outsideCamera.forEach((label) => {
              label.visible = false;
            })
          },
        },
        {
          name: '楼层',
          goFunction: () => {
            const option = {
              position: new THREE.Vector3(171.69, 152.88, -107.51),
              target: new THREE.Vector3(22.48, 76.19, 86.01),
              finish: () => {
                globalVariable.canOperate = true;
                globalVariable.buildingLabels.forEach((label) => {
                  label.visible = true;
                });
              },
              duration: 1000
            };
            globalVariable.canOperate = false;
            app.flyTo(option);
          },
          backFunctioon: () => {
            globalVariable.buildingLabels.forEach((label) => {
              label.visible = false;
            });

            cameraLabel.forEach((label) => {
              label.visible = false;
            });
            cameraLabel = [];

            app.restoreAllMaterial();

            if (globalVariable.currBuilding !== null && globalVariable.currLayer >= 0) {
              globalVariable.currBuilding.children.forEach((layer) => {
                layer.position.set(layer.originalPosition.x, layer.originalPosition.y, layer.originalPosition.z);
              });
            }
            globalVariable.currBuilding = null;
            globalVariable.currLayer = -1;
            this.currLayer = '全楼';
            this.isShowLayerSelector = false;

            app.restoreEnvMap();
            //app.timeSelect.style.display = 'block';
          },
        },
        {
          name: '消防',
          goFunction: () => {
            //app.scene.add(app.fireCube);
            app.wireframeAllMaterial();
            app.scene.background = blackColor;
            //app.timeSelect.style.display = 'none';
            const option = {
              position: new THREE.Vector3(171.2, 42.65, -87.76),
              target: new THREE.Vector3(27.70, 75.25, 90.49),
              finish: () => {
                globalVariable.canOperate = true;
              },
              duration: 1000
            };
            globalVariable.canOperate = false;
            app.flyTo(option);
          },
          backFunctioon: () => {
            app.fireCube.visible = false;
            //app.fireCube.children[0].visible = false;
            //app.scene.remove(app.fireCube);
            app.restoreAllMaterial();
            app.restoreEnvMap();
            //app.timeSelect.style.display = 'block';
          },
        }
      ],
      videoDisplayStyle: {
        show: false,
        x: 0,
        y: 0,
        cameraid: '',
        cameraname: '',
        address: ''
      },
      layerSelectorStyle: {
        left: '72%',
        height: '400px',
        bottom: '80px'
      }
    }
  },
  methods: {
    async initApp() {
      let _this = this;
      websocket = new WebSocket('ws://114.55.73.219:8081/v1/ws');
      websocket.addEventListener('message',function(data){
        _this.messageHander(data);
      });
      app = new ThreeApp('screen');
      app.timeSelect = document.getElementById('timeSelect');
      window.app = app;
      app.initApp();
      await Loader(app);
      app.render();
    },
    messageHander(data){
      //console.log(data);
      const event = JSON.parse(data.data);
      if(event.type === 'fire'){
        app.fireMessage(event);
      }else{
        this.$refs.videoDisplay.messageHander(event);
      }
    },
    changeLayer(item, key) {
      let _this = this;
      this.currLayer = item;

      let oldLayer;
      let newLayer;

      cameraLabel.forEach((label) => {
        label.visible = false;
      });
      cameraLabel = [];

      if (globalVariable.currLayer < 0) {
        oldLayer = globalVariable.currBuilding.userData.layers;
      } else {
        oldLayer = globalVariable.currLayer;
      }
      if (key === 0) {
        newLayer = globalVariable.currBuilding.userData.layers;

      } else {
        newLayer = key;
      }
      globalVariable.currBuilding.children.forEach((layer) => {
        if (layer.userData.layer > newLayer && layer.userData.layer <= oldLayer) {
          const position = new THREE.Vector3(layer.position.x, layer.position.y + 100, layer.z);
          app.moveModel(layer, position);
        } else if (layer.userData.layer <= newLayer && layer.userData.layer > oldLayer) {
          if (layer.userData.layer === newLayer) {
            app.moveModel(layer, layer.originalPosition, () => {
              _this.flyToLayer(layer);
            });
          } else {
            app.moveModel(layer, layer.originalPosition);
          }
        } else {
          if (layer.userData.layer === newLayer) {
            _this.flyToLayer(layer);
          }
        }
      });
      if (key == 0) {
        globalVariable.currLayer = -1;
      } else {
        globalVariable.currLayer = key;
      }
    },
    flyToLayer(layer) {
      const position = app.getModelWorldPostion(layer);
      let targetPosition = new THREE.Vector3();
      let cameraPosition = new THREE.Vector3();
      if (layer.name.includes('RF')) {
        targetPosition.set(position.x, position.y / 2, position.z);
        cameraPosition.set(targetPosition.x + 140, targetPosition.y + 140, targetPosition.z - 140);
      } else {
        targetPosition.set(position.x, position.y + layer.userData.height, position.z);
        cameraPosition.set(targetPosition.x + 40, targetPosition.y + layer.userData.height + 40, targetPosition.z - 40);
      }
      const option = {
        position: cameraPosition,
        target: targetPosition,
        finish: () => {
          globalVariable.canOperate = true;
          if (layer.name.includes('RF')) {
            return;
          }
          layer.traverse((obj) => {
            if (obj.userData.isCss23D) {
              obj.visible = true;
              cameraLabel.push(obj);
            }
          });
        },
        duration: 1000
      };
      globalVariable.canOperate = false;
      app.flyTo(option);
    }
  },
  mounted() {
    let _this = this;
    this.$EventBus.$on('changeLoading', (val) => {
      this.loadingNumber = val;
    });
    this.$EventBus.$on('timeChange', (val) => {
      globalVariable.night = val;
      if (val) {
        if (globalVariable.currBuilding === null) {
          app.scene.background = app.nightEnvMap;
        }
        app.directionalLight.intensity = 2;
        app.scene.fog.color.set(0x111111);

        //app.model.traverse((obj) => {
                 
          // if(obj.name.includes('玻璃') && !obj.parent.name.includes('RF')){
          //   let randomInt = app.getRandomInt(0,1);
          //   if(randomInt == 0){
          //     obj.material = globalVariable.glowGlassMaterial;
          //     obj.originalMaterial = globalVariable.glowGlassMaterial;
          //   }else{
          //     obj.material = globalVariable.glassMaterial;
          //     obj.originalMaterial = globalVariable.glassMaterial;
          //   }
          // }

        //});
      } else {
        if (globalVariable.currBuilding === null) {
          app.scene.background = app.dayEnvMap;
        }
        app.directionalLight.intensity = 7;
        app.scene.fog.color.set(0xaaaaaa);

        // app.model.traverse((obj) => {
                 
        //          if(obj.name.includes('玻璃')){
        //            obj.material = globalVariable.glassMaterial;
        //            obj.originalMaterial = globalVariable.glassMaterial;
        //          }
       
        //        });
      }
    });

    this.$EventBus.$on('controlChange', (newVal, oldVal) => {
      globalVariable.currPage = newVal;
      if (oldVal >= 0) {
        const oldControl = this.controls[oldVal];
        oldControl.backFunctioon();
      }
      if (newVal >= 0) {
        const newControl = this.controls[newVal];
        newControl.goFunction();
      }
    });

    this.$EventBus.$on('openCamera', (cameraid,cameraname, x, y) => {
      this.videoDisplayStyle.x = x;
      this.videoDisplayStyle.y = y;
      this.videoDisplayStyle.cameraname = cameraname;
      //this.videoDisplayStyle.address = cameraid;
      this.videoDisplayStyle.show = true;
      this.$refs.videoDisplay.play(cameraid);
    });

    this.$EventBus.$on('videoDisplayColse', () => {
      this.videoDisplayStyle.show = false;
    });

    this.$EventBus.$on('buildSelected', (buildName) => {
      window.app.scene.background = blackColor;
      globalVariable.buildingLabels.forEach((lable) => {
        lable.visible = false;
      });

      globalVariable.buildings.forEach((building) => {
        if (building.name == buildName) {
          globalVariable.currBuilding = building;
        }
      });

      globalVariable.currLayer = -1;
      app.transparentAllMaterial();
      app.restoreBuildingMaterial(globalVariable.currBuilding);

      globalVariable.currBuilding.children.forEach((layer) => {
        if (layer.name.includes('RF')) {
          const position = app.getModelWorldPostion(layer);

          const option = {
            position: new THREE.Vector3(position.x + 140, position.y / 2 + 140, position.z - 140),
            target: new THREE.Vector3(position.x, position.y / 2, position.z),
            finish: () => {

              this.currLayer = '全楼';
              this.layerData = ['全楼'];
              for (let i = 1; i < globalVariable.currBuilding.userData.layers; i++) {
                this.layerData.push(`${i}F`);
              }

              let height = 24 * this.layerData.length;

              if (height > app.element.offsetHeight - 200) {
                height = app.element.offsetHeight - 200;
              }
              this.layerSelectorStyle.height = `${height}px`;
              this.isShowLayerSelector = true;

            },
            duration: 1000
          };
          app.flyTo(option);
        }
      });
    });

    document.addEventListener('contextmenu', function (event) {
      event.preventDefault();
      if (_this.videoDisplayStyle.show) {
        _this.$refs.videoDisplay.stop();
        _this.videoDisplayStyle.show = false;
      } else if (globalVariable.currBuilding !== null) {
        if (globalVariable.currLayer < 0) {
          app.restoreEnvMap();
          app.restoreAllMaterial();
          globalVariable.currBuilding = null;
          _this.isShowLayerSelector = false;
          const option = {
            position: new THREE.Vector3(171.69, 152.88, -107.51),
            target: new THREE.Vector3(22.48, 76.19, 86.01),
            finish: () => {
              globalVariable.buildingLabels.forEach((label) => {
                label.visible = true;
              });
            },
            duration: 1000
          };
          app.flyTo(option);
        } else {
          _this.changeLayer('全楼', 0);
        }
      }
    });

    document.addEventListener('sendMessage',function(msg) {
       websocket.send(JSON.stringify(msg));
    });

    this.initApp();
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
}

.screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
}

.control {
  height: 5%;
  width: 100%;
  position: fixed;
  bottom: 3%;
  z-index: 3;
  float: left;
  font-size: 30px;
  margin-bottom: 10px;
}

.timeSelect {
  position: absolute;
  bottom: 50px;
  right: 80px;
  z-index: 4;
  width: 100px;
}
</style>
