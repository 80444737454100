<template>
    <div class="header animated fadeInDown">
        <h1>园区</h1>
    </div>
</template>
<script>
export default{
    name: 'BigHeader',
    components: {},
    props: {},
    data(){
        return {};
    },
    mounted(){}
}
</script>
<style lang="less" scoped>
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: url('./../assets/image/header.png') no-repeat center center;
    background-size: 100% 100%;
    z-index: 3;
}

h1{
    color: #bde4ff;
    text-align: center;
    font-size: 36px;
    line-height: 18px;
    letter-spacing: 14px;
}

.explain{
    color: #fff;
    font-size: 24px;
    padding: 0 30px;
}
</style>