import * as THREE from 'three';

import { trunkTexture } from './texture';

export const buildTransparentMaterial = new THREE.MeshBasicMaterial({
    color: 0x00beff,
    transparent: true,
    opacity: 0.1,
    depthWrite: false
});

export const buildWireframeMaterila = new THREE.MeshBasicMaterial({
    color: 0x00beff,
    transparent: true,
    opacity: 0.05,
    wireframe: true
});

export const buildFireWireframeMaterila = new THREE.MeshBasicMaterial({
    color: 0xff4848,
    transparent: true,
    opacity: 0.7,
    wireframe: true
});

export const trunkMaterial = new THREE.MeshBasicMaterial({
    map: trunkTexture,
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 1
});

export const darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } );

export const blackColor = new THREE.Color(0,0,0); 