<template>
    <div class="layers" :style="{left:styles.left,height:styles.height,bottom:styles.bottom}">
        <div
           class="layer"
           v-for="(item,key) in layers"
           :key="key + 'layer'"
           :class="active === item ? 'active' : ''"
           @click="handleClick(item,key)">{{item}}</div>
    </div>
</template>
<script>

import globalVariable from '@/GlobalVariable';

export default{
    name:'LayerSelector',
    comments:{},
    props: {
        layers:{
            type:[Object,Array],
            default: ()=> {
                return {'1F':{}};
            }
        },
        active:{
            type:String,
            default: '1F'
        },
        styles:{
            type:Object,
            default: ()=>{
                return {
                    left:'1%',
                    height: '400px'
                };
            }
        }
    },
    deta(){
        return {};
    },
    methods: {
        handleClick(item,key){
            if(globalVariable.canOperate){
                this.$emit('change',item,key);
            }
        }
    }
}
</script>
<style lang="less" scoped>
.layers{
    position: fixed;
    left: 6%;
    border: 1px solid #5299d3;
    width: 60px;
    height: 400px;
    overflow-y:auto;
    overflow-x: hidden;
    font-size: 12px;
}

.layers .layer{
    width: 100%;
    height: 20px;
    text-align: center;
    justify-content: center;
    line-height: 20px;
    color: #fff;
    border: 2px solid transparent;
    cursor: pointer;
}

.layers .active{
    background: linear-gradient(
        90deg,
        rgba(48,122,182,0) 0%,
        #1d5a8c 31%,
        #5299d3 54%,
        #1d5a8c 76%,
        rgba(29,90,140,0) 100%);
    border: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(179,233,255,0),
        rgba(150,219,255,1),
        rgba(124,207,255,0)
    ) 2 2;
}

::-webkit-scrollbar{
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb{
    background-color: #19477a;
    border-radius: 3px;
}

::-webkit-scrollbar-track{
    background-color: #a7d0ff;
}
</style>