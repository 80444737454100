import * as THREE from 'three';
import bus from '../bus';
import { CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer';
import globalVariable from '@/GlobalVariable';

export function Loader(app){
    return new Promise((resolve) => {
        app.controlGroup = new THREE.Group();
        app.rayModel = [];
        app.lineTextures = [];
        app.scene.add(app.controlGroup);
        const urls = [
            // {
            //     type: 'exr',
            //     url: 'texture/night04_EXR.exr',
            //     onLoad: (texture) => {
            //         const pmremGenerator = new THREE.PMREMGenerator(app.renderer);
            //         pmremGenerator.compileEquirectangularShader();
            //         app.nightEnvMap = pmremGenerator.fromEquirectangular(texture).texture;
            //         pmremGenerator.dispose();
            //     }
            // },
            {
                type: 'rgbe',
                url: 'texture/BlenderGuru-Cloudy.hdr',
                onLoad: (texture) => {
                    const pmremGenerator = new THREE.PMREMGenerator(app.renderer);
                    pmremGenerator.compileEquirectangularShader();
                    app.dayEnvMap = pmremGenerator.fromEquirectangular(texture).texture;
                    app.scene.background = app.dayEnvMap;
                    pmremGenerator.dispose();
                }
            },
            {
                type: 'glb',
                url: 'model/changyang.glb',
                onLoad: (object) => {
                    app.model = object.scene;
                    app.model.traverse((obj) => {
                        obj.originalPosition = obj.position.clone();
                        if(obj.type === 'Mesh'){
                            obj.castShadow = true;
                            obj.receiveShadow = true;

                            if(obj.name.includes('玻璃') && obj.material){
                                obj.material.envMap = app.dayEnvMap;
                            }

                            if(obj.name.includes('camera')){
                                let cameraid = obj.userData.cameraid;
                                let cameraname = obj.userData.cameraname;
                                let innerHtml = `<p class="text">摄像头</p><div class="camera-img">`;
                                let div = document.createElement('div');
                                div.className = 'camera-3d';
                                div.innerHTML = innerHtml;
                                div.cameraid = cameraid;

                                div.onclick = (event) => {
                                    bus.$emit('openCamera',cameraid,cameraname,event.x,event.y);
                                };
                                const label = new CSS3DSprite(div);
                                label.userData.isCss23D = true;
                                
                                label.name = 'cssLeble';
                                
                                obj.add(label);
                                if(obj.userData.outside){
                                    label.position.set(0, 5.5, 0);
                                    label.scale.set(0.1,0.1,0.1);
                                    label.visible = true;
                                    globalVariable.outsideCamera.push(label);
                                }else{
                                    label.position.set(0, 2.5, 0);
                                    label.scale.set(0.03,0.03,0.03);
                                    label.visible = false;
                                }
                            }

                            if(obj.material){

                                if(obj.name.includes('玻璃')){
                                    obj.material.transparent = true;
                                    obj.material.opacity = 0.7;
                                    globalVariable.exteriorWall.push(obj);
                                    globalVariable.glassMaterial = obj.material;
                                }else{
                                    obj.material.side = THREE.FrontSide;                                
                                }
    
                                if(obj.name.includes('光带') || obj.name.includes('�?')){
                                    obj.layers.enable(1);
                                    obj.material.emissive.set(0xeeeeee);
                                    obj.material.emissiveIntensity = 0.5;
                                }
                                
                                obj.originalMaterial = obj.material;
                                
                            }
                        }else{
                            if(obj.name.includes('RF')){
                                let buildName = obj.parent.name;
                                //console.log(buildName);
                                let innerHtml = `<p class="text">${buildName}</p>`;
                                let div = document.createElement('div');
                                div.className = 'build-3d';
                                div.innerHTML = innerHtml;

                                div.onclick = (event) => {
                                    bus.$emit('buildSelected',buildName,event.x,event.y);
                                };
                                const label = new CSS3DSprite(div);
                                label.userData.isCss23D = true;
                                label.position.set(0, obj.userData.height + 5.5, 0);
                                label.name = 'cssLeble';
                                label.scale.set(0.1,0.1,0.1);
                                label.visible = false;
                                obj.add(label);
                                globalVariable.buildingLabels.push(label);
                                globalVariable.buildings.push(obj.parent);
                            }
                        }
                    });
                    globalVariable.glowGlassMaterial = globalVariable.glassMaterial.clone();
                    globalVariable.glowGlassMaterial.emissive.set(0xffffcc);
                    globalVariable.glowGlassMaterial.emissiveIntensity  = 0.3;
                }
            }];

        app.iterateLoad(
            urls,
            (xhr,index) => {
                let pro = index * 100;
                if(xhr.total > 0){
                    pro = pro + xhr.loaded * 100 / xhr.total;
                }
                let progress = parseInt(pro / urls.length);
                bus.$emit('changeLoading',progress);
            },
            () => {
                app.scene.add(app.model);
                bus.$emit('changeLoading',100);
                resolve();
            }
        );
    });
}