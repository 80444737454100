import * as THREE from 'three';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
//import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { buildWireframeMaterila,buildFireWireframeMaterila,darkMaterial } from './material';
import bloomLayer from './BloomLayer';
import globalVariable from '@/GlobalVariable';
import { buildTransparentMaterial, blackColor } from './material';
import TWEEN from 'three/examples/jsm/libs/tween.module';
import createFire from '@/three/FireMesh';
import { CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer';

export default class ThreeApp {

    constructor(id) {
        this.id = id;
        this.element = document.getElementById(id);
        this.needGui = false;
        this.needHelper = false;
        this.needStats = false;
        this.preFireRoom = null;
    }

    initApp() {
        let _app = this;
        let width = this.element.offsetWidth;
        let height = this.element.offsetHeight;
        this.scene = new THREE.Scene();
        this.textureLoader = new THREE.TextureLoader();
        this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 8000);
        this.camera.position.set(179.02, 138.55, -179.12);  //x: 179.02, y: 138.55, z: -179.12
                                                            //x: 171.69, y: 152.88, z: -107.51
                                                            //x: 171.2, y: 42.65, z: -87.76 
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(width, height);
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.toneMappingExposure = 1;


        this.cssRenderer = new CSS3DRenderer();
        this.cssRenderer.setSize(width, height);
        this.cssRenderer.domElement.style.position = 'absolute';
        this.cssRenderer.domElement.style.top = 0;
        this.cssRenderer.domElement.style.pointerEvents = 'none';

        this.element.appendChild(this.renderer.domElement);
        this.element.appendChild(this.cssRenderer.domElement);

        // this.group = new THREE.Group();
        // this.group.name = 'css';
        // this.scene.add(this.group);

        this.fireCube = createFire();
        this.fireCube.scale.set(5,5,5);
        this.fireCube.position.set(0,10,0);
        this.fireCube.visible = false;


        let innerHtml = '<p>2222</P>';
        this.fireDiv = document.createElement('div');
        this.fireDiv.innerHTML = innerHtml;
        this.fireDiv.className = "fire-div";

        const roomLabel = new CSS3DSprite(this.fireDiv);
        roomLabel.userData.isCss23D = true;
        roomLabel.position.set(0,-2,0);
        roomLabel.scale.set(0.1,0.1,0.1);
        this.fireCube.add(roomLabel);
        this.scene.add(this.fireCube);
        this.fireCube.visible = false;

        if (this.needGui) {
            this.gui = new GUI();
        }


        this.clock = new THREE.Clock();

        this.scene.fog = new THREE.Fog(0xaaaaaa, 200, 2000)

        window.addEventListener('resize', function () {
            _app.camera.aspect = _app.element.offsetWidth / _app.element.offsetHeight;
            _app.camera.updateProjectionMatrix();
            _app.renderer.setSize(_app.element.offsetWidth, _app.element.offsetHeight);
            if (_app.cssRenderer) {
                _app.cssRenderer.setSize(_app.element.offsetWidth, _app.element.offsetHeight);
            }
        });


        this.bloomParams = {
            threshold: 0.0,
            strength: 0.3,
            radius: 0.0,
            exposure: 1.0
        };

        const renderScene = new RenderPass(this.scene, this.camera);

        this.bloomPass = new UnrealBloomPass(new THREE.Vector2(width, height), 1.5, 0.4, 0.85);
        this.bloomPass.threshold = this.bloomParams.threshold;
        this.bloomPass.strength = this.bloomParams.strength;
        this.bloomPass.radius = this.bloomParams.radius;

        this.bloomComposer = new EffectComposer(this.renderer);
        this.bloomComposer.renderToScreen = false;
        this.bloomComposer.addPass(renderScene);
        this.bloomComposer.addPass(this.bloomPass);

        const mixPass = new ShaderPass(
            new THREE.ShaderMaterial({
                uniforms: {
                    baseTexture: { value: null },
                    bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
                },
                vertexShader: `
                varying vec2 vUv;
			    void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
                fragmentShader: `
                uniform sampler2D baseTexture;
			    uniform sampler2D bloomTexture;
                varying vec2 vUv;

                void main() {
                    gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
                }`,
                defines: {}
            }), 'baseTexture'
        );
        mixPass.needsSwap = true;

        //const outputPass = new OutputPass();

        this.finalComposer = new EffectComposer(this.renderer);
        this.finalComposer.addPass(renderScene);
        this.finalComposer.addPass(mixPass);
        //this.finalComposer.addPass( outputPass );

        if (this.needGui) {
            const bloomFolder = this.gui.addFolder('bloom');
            bloomFolder.add(this.bloomParams, 'threshold', 0.0, 1.0).onChange(function (value) {
                _app.bloomPass.threshold = Number(value);
            });

            bloomFolder.add(this.bloomParams, 'strength', 0.0, 3).onChange(function (value) {
                _app.bloomPass.strength = Number(value);
            });

            bloomFolder.add(this.bloomParams, 'radius', 0.0, 1.0).step(0.01).onChange(function (value) {
                _app.bloomPass.radius = Number(value);
            });
        }



        this.initOrbitControls();
        this.initHelper();
        this.initLight();
        this.initStats();
    }



    initLight() {
        this.ambientLight = new THREE.AmbientLight('#aaaaaa', 0.4); //'#6d78d0'
        this.ambientLight.castShadow = false;
        this.scene.add(this.ambientLight);

        if (this.needGui) {
            const folderAmbbient = this.gui.addFolder('基础光源');
            folderAmbbient.close();
            folderAmbbient.addColor(this.ambientLight, 'color');
            folderAmbbient.add(this.ambientLight, 'intensity', 0, 2.0, 0.1);
        }


        this.directionalLight = new THREE.DirectionalLight('#bababa', 7);  //'#3e9ae0'
        this.directionalLight.position.set(100, 200, -100);
        this.directionalLight.castShadow = true;
        this.scene.add(this.directionalLight);

        if (this.needGui) {
            const folderDirectional = this.gui.addFolder('平行灯');
            folderDirectional.close();
            folderDirectional.addColor(this.directionalLight, 'color');
            folderDirectional.add(this.directionalLight, 'intensity', 0, 10.0, 0.1);
        }

        this.directionalLight.shadow.mapSize.width = 8192;
        this.directionalLight.shadow.mapSize.height = 8192;
        this.directionalLight.shadow.camera.near = 1;
        this.directionalLight.shadow.camera.far = 450;
        this.directionalLight.shadow.camera.top = 300;
        this.directionalLight.shadow.camera.bottom = -250;
        this.directionalLight.shadow.camera.left = -250;
        this.directionalLight.shadow.camera.right = 250;
        //this.directionalLight.shadow.bias = -0.00001;
        //directionalLight.shadow.normalBias = -0.0005;

        if (this.needHelper) {
            const helper = new THREE.CameraHelper(this.directionalLight.shadow.camera);
            this.scene.add(helper);
        }
    }

    initOrbitControls() {
        let controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.enableDamping = true;
        controls.enableZoom = true;
        controls.autoRotate = false;
        controls.autoRotateSpeed = 0.5;
        controls.enablePan = true;

        controls.target.set(26.98, 77.93, 89.35);//x: 26.98, y: 77.93, z: 89.35
                                                 //x: 22.48, y: 76.19, z: 86.01
                                                 //x: 27.7, y: 75.25, z: 90.49
                                                   
        let cameraStartPostion;
        let cameraEndPostion;
        controls.addEventListener('start', () => {
            cameraStartPostion = this.camera.position.clone();
            controls.isClickLock = false;
        });

        controls.addEventListener('end', () => {
            cameraEndPostion = this.camera.position.clone();
            const startXYZ = Object.values(cameraStartPostion).reduce(function (prev, curr) {
                return prev + curr;
            });
            const endXYZ = Object.values(cameraEndPostion).reduce(function (prev, curr) {
                return prev + curr;
            });

            if (Math.abs(endXYZ - startXYZ) < 0.0001) {
                controls.isClickLock = false;
            } else {
                controls.isClickLock = true;
            }
            cameraStartPostion = null;
            cameraEndPostion = null;
        });

        this.controls = controls;
    }

    initHelper() {
        if (this.needHelper) {
            this.scene.add(new THREE.AxesHelper(100));
        }
    }

    initStats() {
        if (this.needStats) {
            this.stats = new Stats();
            //console.log(this.stats);
            this.element.appendChild(this.stats.dom);
        }
    }

    loaderModel(option) {
        switch (option.type) {
            case 'obj':
                if (!this.objLoader) {
                    this.objLoader = new OBJLoader();
                }
                if (!this.mtlLoader) {
                    this.mtlLoader = new MTLLoader();
                }
                this.mtlLoader.load(option.mtlUrl || '', (materials) => {
                    materials.preload();
                    this.objLoader.setMaterials(materials)
                        .load(option.url, option.onProgress, option.onError);
                });
                break;
            case 'gltf':
            case 'glb':
                if (!this.gltfLoader) {
                    this.gltfLoader = new GLTFLoader();
                    let dracoLoader = new DRACOLoader();
                    dracoLoader.setDecoderPath('draco/');
                    this.gltfLoader.setDRACOLoader(dracoLoader);
                }
                this.gltfLoader.load(option.url, option.onLoad, option.onProgress, option.onError);
                break;
            case 'fbx':
                if (!this.fbxLoader) {
                    this.fbxLoader = new FBXLoader();
                }
                this.fbxLoader.load(option.url, option.onLoad, option.onProgress, option.onError);
                break;
            case 'rgbe':
                if (!this.rgbeLoader) {
                    this.rgbeLoader = new RGBELoader();
                }
                this.rgbeLoader.load(option.url, option.onLoad, option.onProgress, option.onError);
                break;
            case 'exr':
                if (!this.exrLoader) {
                    this.exrLoader = new EXRLoader();
                }
                this.exrLoader.load(option.url, option.onLoad, option.onProgress, option.onError);
                break;
            case 'mp3':
            case 'wav':
                if (!this.audioLoader) {
                    this.audioLoader = new THREE.AudioLoader();
                }
                this.audioLoader.load(option.url, option.onLoad, option.onProgress, option.onError);
                break;
            default:
                console.error('当前只支持obj,gltf,glb,fbx,rgbe格式');
                break;
        }
    }

    iterateLoad(objFiles, onProgress, onAllLoad) {
        let fileIndex = 0;
        let that = this;
        function iterateLoadForlt() {
            that.loaderModel({
                type: objFiles[fileIndex].type,
                dracoUrl: objFiles[fileIndex].dracoUrl,
                mtlUrl: objFiles[fileIndex].mtlUrl,
                url: objFiles[fileIndex].url,
                onLoad: function (object) {
                    if (objFiles[fileIndex].onLoad) {
                        objFiles[fileIndex].onLoad(object);
                    }
                    fileIndex++;
                    if (fileIndex < objFiles.length) {
                        iterateLoadForlt();
                    } else {
                        if (onAllLoad) {
                            onAllLoad();
                        }
                    }
                },
                onProgress: function (xhr) {
                    if (objFiles[fileIndex].onProgress) {
                        objFiles[fileIndex].onProgress(xhr, fileIndex);
                    }
                    if (onProgress) {
                        onProgress(xhr, fileIndex);
                    }
                }
            })

        }
        iterateLoadForlt();
    }

    render() {
        let _app = this;
        let delta = this.clock.getDelta();
        let elapsed = this.clock.getElapsedTime();
        this.controls.update(delta);
        if(this.camera.position.y < 2.0){
            this.camera.position.y = 2.0;
        }
                                                             
        var invModelMatrix = this.fireCube.material.uniforms.invModelMatrix.value;
        this.fireCube.updateMatrix();
        invModelMatrix.copy(this.fireCube.matrixWorld);
        invModelMatrix.invert();
        this.fireCube.material.uniforms.time.value = elapsed;
        this.fireCube.material.uniforms.invModelMatrix.value = invModelMatrix;
        this.fireCube.material.uniforms.scale.value = this.fireCube.scale;


        if (globalVariable.night && globalVariable.currPage < 2) {
            this.scene.background = blackColor;

            if (globalVariable.currBuilding === null) {
                this.darkenAllNonLayer();
            } else {
                this.darkenAllMaterial();
                this.darkenBuildingNonLayer(globalVariable.currBuilding);
            }
            this.bloomComposer.render();
            if (globalVariable.currBuilding === null) {
                this.restoreAllMaterial();
            }else{
                this.transparentAllMaterial();
                this.restoreBuildingMaterial(globalVariable.currBuilding);
            } 
            if (globalVariable.currBuilding === null) {
                this.scene.background = this.nightEnvMap;
            }
            this.finalComposer.render();
        } else {
            this.renderer.render(this.scene, this.camera);
        }

        this.cssRenderer.render(this.scene, this.camera);
        if(this.needStats){
            this.stats.update();
        }
        
        this.frameId = requestAnimationFrame(() => {
            _app.render();
        });
        TWEEN.update();
    }

    darkenSingleMaterial(obj) {
        if (obj.material) {
            obj.material = darkMaterial;
        }
    }

    darkenAllMaterial(){
        this.scene.traverse(this.darkenSingleMaterial)
    }

    darkenSingleNonLayer(obj) {
        if (obj.material) {
            if (bloomLayer.test(obj.layers)) {
                obj.material = obj.originalMaterial;
            } else {
                obj.material = darkMaterial;
            }
        }
    }

    darkenAllNonLayer(){
        this.model.traverse(this.darkenSingleNonLayer);
    }

    darkenBuildingNonLayer(building){
        building.traverse(this.darkenSingleNonLayer);
    }

    transparentSingleMaterial(obj) {
        if (obj.material) {
            obj.material = buildTransparentMaterial;
        }
    }

    transparentAllMaterial(){
        this.model.traverse(this.transparentSingleMaterial);
    }

    wireframeSingleMaterial(obj){
        if(obj.material){
            obj.material = buildWireframeMaterila;
        }
    }

    wireframeAllMaterial(){
        this.model.traverse(this.wireframeSingleMaterial);
    }

    restoreSingleMaterial(obj) {
        if (obj.material) {
            obj.material = obj.originalMaterial;
        }
    }

    restoreAllMaterial(){
        this.model.traverse(this.restoreSingleMaterial);
    }

    restoreBuildingMaterial(building){
        building.traverse(this.restoreSingleMaterial);
    }
    restoreEnvMap() {
        if (globalVariable.night) {
            this.scene.background = this.nightEnvMap;
        } else {
            this.scene.background = this.dayEnvMap;
        }
    }

    getModelWorldPostion(model) {
        this.scene.updateMatrixWorld(true);
        const worldPosition = new THREE.Vector3();
        model.getWorldPosition(worldPosition);
        return worldPosition;
    }

    flyTo(option){
        const tween = new TWEEN.Tween({
            x1: this.camera.position.x,
            y1: this.camera.position.y,
            z1: this.camera.position.z,
            x2: this.controls.target.x,
            y2: this.controls.target.y,
            z2: this.controls.target.z
        }).to({
            x1: option.position.x,
            y1: option.position.y,
            z1: option.position.z,
            x2: option.target.x,
            y2: option.target.y,
            z2: option.target.z
        },option.duration)
        .easing(TWEEN.Easing.Linear.None);

        tween.onUpdate(() => {
            this.controls.enabled = false;
            this.camera.position.set(tween._object.x1,tween._object.y1,tween._object.z1);
            this.controls.target.set(tween._object.x2,tween._object.y2,tween._object.z2);
            this.controls.update();
        });

        tween.onStart(() => {
            this.controls.enabled = false;
        });

        tween.onComplete(() => {
            this.controls.enabled = true;
            if(option.finish){
                option.finish();
            }
            
        });

        tween.start();
        TWEEN.add(tween);
    }

    moveModel(model,position,finish){
        const tween = new TWEEN.Tween({
            x: model.position.x,
            y: model.position.y,
            z: model.position.z
        }).to({
            x: position.x,
            y: position.y,
            z: position.z
        },1000)
        .easing(TWEEN.Easing.Linear.None);

        tween.onUpdate(() => {
            model.position.set(tween._object.x,tween._object.y,tween._object.z);
        });

        if(finish){
            tween.onComplete(() => {
                finish();
            })
        }



        tween.start();
        TWEEN.add(tween);
    }

    getRandomInt(min,max){
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    fireMessage(event){

        if(globalVariable.currPage != 2){
            //console.log('不在消防页面');
            return;
        }
        let _this = this;
        this.model.traverse((obj) => {
            if(obj.name.includes('房间')){
                if(obj.userData.roomid === event.message){

                    if(_this.preFireRoom != null){
                        _this.preFireRoom.material = buildWireframeMaterila;
                    }

                    _this.preFireRoom = obj;
                    obj.material = buildFireWireframeMaterila;

                    let pos = _this.getModelWorldPostion(obj);
                    let room = obj.userData.roomname;
                    _this.fireCube.position.set(pos.x,pos.y + 2.5,pos.z);
                    _this.fireCube.visible = true;
                    //_this.fireCube.children[0].visible = true;
                    
                    let innerHtml = `<p class="fire-text">${room}</p>`;
                    _this.fireDiv.innerHTML = innerHtml;
                    
                    const option = {
                        position: new THREE.Vector3(pos.x + 85, pos.y + 0.5, pos.z - 70),
                        target: new THREE.Vector3(pos.x, pos.y, pos.z),
                        finish: () => {
                        },
                        duration: 1000
                    };
                    _this.flyTo(option);
                }
            }
        });
    }
}