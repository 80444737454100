<template>
  <div class="container">
    <form>
      <label v-for="(item, index) in controls" v-bind:key="index" @click="activeFun(item, index)">
        <input type="radio" name="radio" :checked="index === activeVar" />
        <span>{{ item.name }}</span>
      </label>
    </form>
  </div>
</template>
<script>
import bus from '@/bus';
import globalVariable from '@/GlobalVariable';

export default {
  name: 'BigControl',
  comments: {

  },
  props: ['controls'],
  data() {
    return {
      activeVar: 0
    }
  },
  methods: {
    activeFun(item, index) {
      if(globalVariable.canOperate){
        this.activeVar = index;
      }
    }
  },
  watch: {
    activeVar(newVal, oldVal) {
      if(newVal == oldVal){
        return;
      }
      bus.$emit('controlChange',newVal,oldVal);
    }
  }
}
</script>
<style lang="less" scoped>
.container form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.container form label span {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 20px;
}

.container form label input {
  appearance: none;
  -webkit-appearance: none;
}

.container form label span {
  position: relative;
  padding: 5px 20px 5px 20px;
  color: #21ebff;
  background: rgba(34, 34, 34, 0.5);
  text-decoration: none;
  font-size: 20px;
  transition: 0.5s;
  overflow: hidden;
}

.container form label span:hover {
  background: #21ebff;
  color: #111;
  box-shadow: 0 0 50px #21ebff;
}

.container form label input:checked+span {
  background: #21ebff;
  color: #111;
}

.container form label span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid #21ebff;
  border-left: 2px solid #21ebff;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.container form label span:hover::before {
  width: 98%;
  height: 90%;
  transition-delay: 0s;
}

.container form label span::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-right: 2px solid #21ebff;
  border-bottom: 2px solid #21ebff;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.container form label span:hover::after {
  width: 98%;
  height: 90%;
  transition-delay: 0s;
}
</style>