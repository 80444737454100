const globalVariable = {
    outsideCamera: [],
    buildingLabels:[],
    buildings: [],
    exteriorWall: [],
    night: false,
    currBuilding: null,
    currLayer: -1,
    currPage: 0,
    glassMaterial: null,
    glowGlassMaterial: null,
    canOperate: true
};

export default globalVariable;